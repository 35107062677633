import { Context, createContext, useState, useEffect }                      from "react";
import { Log, getRequestUrl }                                                    from "../utils";
import { AuthContextType }                                                  from "./types";
import { Forage, useEvent }                                                 from "src/@fair";
import { useLocation, useNavigate }                                         from "react-router-dom";
import Axios                                                                from "axios";
import { Permissions, DigiTrakRole, PermissionGroup, ParseRolePermissions } from "@fairview-group/permissions";

export const AuthContext: Context<AuthContextType> = createContext<AuthContextType>({
    token: undefined,
    setToken: undefined,
    role: undefined,
    setRole: undefined,
    awaitable: undefined,
    setAwaitable: undefined,
    username: undefined,
    id: undefined,
    contentAwaitable: undefined,
    setContentAwaitable: undefined,
    name: undefined,
    organization: undefined,
    setOrganization: undefined,
    organizations: undefined,
    setOrganizations: undefined,
    impersonationInfo: undefined,
    setImpersonationInfo: undefined,
    announcements: undefined,
    setAnnouncements: undefined,
    failAuth: undefined,
    previousLocation: undefined,
    setPreviousLocation: undefined
});

export const Auth = ({children}) => {
    const [awaitable, setAwaitable] = useState<boolean>(undefined);
    const [contentAwaitable, setContentAwaitable] = useState<boolean>(undefined);
    const [token, setToken] = useState<string>(undefined);
    const [role, setRole] = useState<PermissionGroup>(
        ParseRolePermissions({
            name: "temp", 
            permissions: {}
        })
    );
    
    // const [role, setRole] = useState<PermissionGroup>(undefined);
    const [username, setUsername] = useState<string>(undefined);
    const [id, setId] = useState<string>(undefined);
    const [name, setName] = useState<{ first: string, last: string }>(undefined);
    const [organization, setOrganization] = useState<string>(undefined);
    const [organizations, setOrganizations] = useState<Array<any>>(undefined);
    const [impersonationInfo, setImpersonationInfo] = useState<any>(undefined);
    const [announcements, setAnnouncements] = useState<Array<any>>([]);
    const [businessDiscussions, setBusinessDiscussions] = useState<Array<any>>([]);
    const [typeData, setTypeData] = useState<{ types: Array<any>, typeMap: any }>({ types: [], typeMap: {} });
    const [categoryData, setCategoryData] = useState<{ categories: Array<any>, categoryMap: any }>({ categories: [], categoryMap: {} });
    const [previousLocation, setPreviousLocation] = useState<string>(undefined);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        Log(previousLocation)
    }, [previousLocation])

    /***
     * Fails AuthContext's awaitable state
     * @returns {Promise<void>}
     */
    const failAuth = async () => {
        setPreviousLocation(location.pathname + (location.search ?? ""));
        Log("[ Auth ] - Auth failed.");
        await Forage.removeItem("token");
        navigate("/login", {
            replace: true
        });
        setAwaitable(false);
        setContentAwaitable(false);
        setToken(undefined);
    };

    /***
     * Handles authentication changes and validates token
     * @returns {Promise<void>}
     */
    const authHandler = async () => {
//        setContentAwaitable( undefined );

        console.log("[ Auth ] - Being called...");

        try {

            const t: string = await Forage.getItem("token");
            // console.log(t);
            if (!t) {

                console.log("[ Auth ] - No token in IndexedDB found.");
                if (location.pathname !== "/login") {
                    setPreviousLocation(location.pathname + (location.search ?? ""));
                }
                setAwaitable(false);
                throw new Error("Authentication failed.");
            }

            console.log("[ Auth ] - Found existing token stored in IndexedDB.");
            console.log("[ Auth ] - Checking validity of token...");

            // Validate token
            let endpoint = "/auth/verify";
            const url = getRequestUrl(endpoint);

            const res = await Axios.post(url, {
                token: t
            });

            if (res.data.error || !res.data.payload) {
                console.log("[ Auth ] - ERROR: Token was invalidated.");
                failAuth().finally();
                throw new Error("Authentication failed.");
            }

            console.log("[ Auth ] - SUCCESS: Token was validated.");
            console.log("[ Auth ] - User found: ", res.data.payload);

            console.log("[ Auth ] - Checking user's role...");

            // Get permissions
            if (res.data.payload?.role) {
                const r: PermissionGroup = ParseRolePermissions(res.data.payload.role);
                if (!r.account.has(Permissions.account.login.permission) && res.data.payload.organization) {
                    console.log("[ Auth ] - Role Validation failed.");
                    failAuth().finally();
                    throw new Error("Authentication failed.");
                }
    
                console.log("[ Auth ] - Role Validation successful. Syncing AuthContext.token to IndexedDB token.");
                
                const roleStringNew = JSON.stringify(r);
                const roleStringOld = JSON.stringify(role);
    
                if (role === undefined || roleStringNew !== roleStringOld) {
                    // console.log( "setting role" );
                    setRole(r);
                }
            } 
            // else {
            //     const noOrgRole: PermissionGroup = ParseRolePermissions({name: "Default", permissions: {account: 0}});
            //     setRole(noOrgRole);
            // }
            // console.log(organization);
            const organizationsOld = JSON.stringify(organizations);
            const organizationsNew = JSON.stringify(res.data.payload?.organizations);

            // console.log(window.self, window.top, window.frameElement, window.self !== window.top);
            if (JSON.stringify(impersonationInfo) !== JSON.stringify(res.data.payload.imp)) {
                if (!res.data.payload.imp) {
                    setImpersonationInfo(res.data.payload.imp);
                } else {
                    if (window.self !== window.top) {
                        setImpersonationInfo(res.data.payload.imp);
                    } else  {
                        failAuth().finally();
                    }
                }
            }

            if (t !== token) {
                // console.log( "setting token" );
               setToken(t);
           }
            if (awaitable === undefined) {
                //  console.log( "setting awaitable" );
                setAwaitable(true);
            }
            if (contentAwaitable === undefined) {
                //  console.log( "setting contentAwaitable" );
                setContentAwaitable(true);
            }

            if (JSON.stringify(res.data.payload.announcements) !== JSON.stringify(announcements)) {
                setAnnouncements(res.data.payload.announcements);
            }

            if (JSON.stringify(res.data.payload.business_discussions) !== JSON.stringify(businessDiscussions)) {
                setBusinessDiscussions(res.data.payload.business_discussions);
            }

            if (JSON.stringify(res.data.payload.typeData) !== JSON.stringify(typeData)) {
                setTypeData(res.data.payload.typeData);
            }

            if (JSON.stringify(res.data.payload.categoryData) !== JSON.stringify(categoryData)) {
                setCategoryData(res.data.payload.categoryData);
            }

            if (username !== res.data.payload.username) {
                //  console.log( "setting username" );
                setUsername(res.data.payload.username);
            }

            if (id !== res.data.payload.id) {
                setId(res.data.payload.id);
            }
            if (JSON.stringify(name) !== JSON.stringify(res.data.payload.name)) {
                //  console.log( "setting contentAwaitable" );
                setName(res.data.payload.name);
            }

            if (organizationsNew !== organizationsOld || organizations === undefined) {
                setOrganizations(res.data.payload?.organizations);
            }
            if (JSON.stringify(organization) !== JSON.stringify(res.data.payload?.organization) || organization === undefined) {
                setOrganization(res.data.payload.organization);
            }
            setPreviousLocation(undefined)
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        authHandler().finally();
    }, [location]);

    useEffect(() => {
        console.log(`[ contentAwaitable ] Changed to ${contentAwaitable} at ${new Date().toISOString()}`);
    }, [contentAwaitable]);

    return (
        <AuthContext.Provider value={{
            token: token,
            setToken: setToken,
            role: role,
            setRole: setRole,
            awaitable: awaitable,
            setAwaitable: setAwaitable,
            username: username,
            id: id,
            contentAwaitable,
            setContentAwaitable,
            name: name,
            organization: organization,
            setOrganization: setOrganization,
            organizations: organizations,
            setOrganizations: setOrganizations,
            impersonationInfo: impersonationInfo,
            setImpersonationInfo: setImpersonationInfo,
            announcements: announcements,
            setAnnouncements: setAnnouncements,
            failAuth: failAuth,
            previousLocation: previousLocation,
            setPreviousLocation: setPreviousLocation
        }} children={children}/>
    );
};

export * from "./types";
export default Auth;
