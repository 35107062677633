import { RouteType, getRouteFromUrl, useRoutes } from "src/routes"
import "./index.scss"
import { Link, useNavigate } from "react-router-dom"
import { BsCalendarWeek, BsClipboard, BsDashCircle, BsPersonLinesFill, BsTable, BsTruck } from "react-icons/bs"
import React, { CSSProperties, Children, Fragment, ReactElement, ReactNode, cloneElement, useContext, useEffect, useId, useMemo, useRef, useState } from "react"
import { classCombine } from "@fairview-group/utils"
import { RiDashboard3Fill, RiOrganizationChart } from "react-icons/ri"
import { TbUserShield } from "react-icons/tb"
import ToolTip from "../tool-tip"
import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdKeyboardArrowUp, MdOutlineAddBusiness, MdOutlineAdminPanelSettings, MdOutlineAssignment, MdOutlineAssignmentInd, MdOutlineCategory, MdOutlineContactSupport, MdOutlineCreate, MdOutlineInventory, MdOutlineInventory2, MdOutlineLibraryAdd, MdOutlineLocationOn, MdOutlineManageAccounts, MdOutlineMiscellaneousServices, MdOutlinePending, MdOutlineSummarize, MdOutlineTableView, MdPending } from "react-icons/md"
import Break from "../break"
import { AiOutlineComment, AiOutlineDashboard, AiOutlineNotification } from "react-icons/ai"
import { HiOutlineDocument, HiOutlineDocumentDuplicate, HiOutlineDocumentReport, HiOutlineMenuAlt1, HiOutlineUserGroup, HiUserGroup } from "react-icons/hi"
import { BiCategory } from "react-icons/bi"
import { FaDollarSign, FaRegEdit, FaRoute, FaTruck, FaWrench } from "react-icons/fa"
import { v4 as uuid } from "uuid";
import { LuClipboardList, LuFileSpreadsheet, LuSettings, LuWrench } from "react-icons/lu"
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2"
import { RiPinDistanceLine } from "react-icons/ri"
import { GrGroup, GrSchedules } from "react-icons/gr"
import { ThemeContext, ThemeContextType, useBreakpoint } from "src/@fair"
import { IoMdClose } from "react-icons/io"
import { getWidth } from "src/shell"
import { AuthContext, AuthContextType } from "src/auth"
import { IoTerminalOutline } from "react-icons/io5"


interface NavIconProps {
    icon: ReactNode,
    text?: string,
    route?: RouteType,
    routes?: Array<RouteType>,
    hiddenRoutes?: Array<RouteType>,
}

const NavIcon = ({ icon, route, routes, text, hiddenRoutes }: NavIconProps) => {
    const themeContext = useContext(ThemeContext);
    const navigate = useNavigate();
    const routeData = getRouteFromUrl()();

    const checkRoute = useMemo(() => {
        // console.log(routeData);
        let main = false;
        let hidden = false;
        if (routes) {

            for (let i = 0; i < routes.length; i++) {
                const $route = routes[i];
                if ($route?.url === routeData.route.url) {
                    main = true;
                    break;
                }
            }
        } else {
            if (route?.url === routeData.route.url) {
                main = true;
            }
        }

        if (hiddenRoutes) {
            for (let i = 0; i < hiddenRoutes.length; i++) {
                const $route = hiddenRoutes[i];
                if ($route?.url === routeData.route.url) {
                    hidden = true;
                    break;
                }
            }
        }
        return {
            main,
            hidden
        };
    }, [route, routes, hiddenRoutes, routeData])

    return (
        ((route ? route?.condition !== false : false) || routes?.find(($route) => $route.condition)) && <ToolTip
            tooltip={text ?? route.pageName}
        >
            <div
                className={classCombine("nav-icon", (checkRoute.hidden || checkRoute.main) ? "active" : "", themeContext.value)}
                onClick={() => {
                    if (!routes) {
                        navigate(route.url);
                    } else {
                        navigate(routes?.find(($route) => $route.condition).url);
                    }
                }}
            >
                <div
                    className={classCombine("nav-icon-container", themeContext.value)}
                >

                    {/* {
                        cloneElement(icon as React.ReactElement)
                    } */}
                    {
                        icon
                    }
                </div>
            </div>
        </ToolTip>

    )
}

interface SideNavOptionProps {
    icon?: ReactNode,
    route?: RouteType,
    routes?: Array<RouteType>,
    children?: ReactNode,
    className?: string,
    hasToolTip?: boolean,
    style?: CSSProperties,
    onClick?: Function
}

const SideNavOption = ({ icon, route, children, className, hasToolTip, style, routes, onClick }: SideNavOptionProps) => {
    const routeData = getRouteFromUrl()();
    const themeContext = useContext(ThemeContext);

    const checkRoutes = () => {
        if (routeData.route.pageName === route.pageName) return true;
        if (routes) {
            for (let i = 0; i < routes.length; i++) {
                if (routeData.route.pageName === routes[i].pageName) return true;
            }
        }
        return false;
    }
    return (
        route?.condition === true &&
        <Link
            className={classCombine("side-nav-option", themeContext.value, className,
                (
                    !hasToolTip
                        ? checkRoutes()
                        : routeData?.route?.url?.includes(route?.url)
                )
                    ? "active" : ""
            )}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            to={route?.url}
            style={style}
        >

            {
                cloneElement((icon ?? route?.icon) as React.ReactElement,)
            }
            {/* <div
                    className={classCombine("side-nav-children")}
                > */}
            {children ?? route.pageName}
            {/* </div> */}

        </Link>
    )
}

interface SideNavExpanderProps extends SideNavOptionProps {
    routes: Array<RouteType>
}

export const SideNavExpander = ({ route, children, icon, routes }: SideNavExpanderProps) => {
    const themeContext = useContext(ThemeContext);
    const [active, setActive] = useState<boolean>(false);
    const [id, setId] = useState(uuid());
    const navId = useId();
    const checkWidth = getWidth()();
    const routeData = getRouteFromUrl()();

    const checkRoute = useMemo(() => {
        for (let i = 0; i < routes.length; i++) {
            const $route = routes[i];
            if ($route.url === routeData.route.url) {
                return true;
                // check = true;
            }
        }
        // if (active) setActive(false);
        return false;
    }, [routeData, checkWidth])


    useEffect(() => {
        if (checkRoute && !document.getElementById("content").classList.contains("expanded")) {
            document.getElementById("content").classList.add("expanded");
        }

        // if (!active) setActive(true);

        return () => {
            if (document.getElementById("content")?.classList?.contains("expanded")) {
                document.getElementById("content").classList.remove("expanded");
            }
        }
    }, [checkRoute])

    return (
        checkRoute &&
        <Fragment
            key={id}
        >
            {

                (
                    (active && !checkWidth)
                    || checkWidth
                )
                    ?
                    <nav
                        id={navId}
                        className={classCombine("side-nav-expander", themeContext.value)}
                    >

                        {(active && !checkWidth) && <div
                            onClick={() => {
                                setActive(false);
                            }}
                            style={{
                                display: "flex",
                                // justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <IoMdClose />
                        </div>}
                        {(active && !checkWidth) && <SideNavBreak />}
                        {children}
                    </nav>
                    :
                    <div
                        className={classCombine("side-nav-expander-btn", themeContext.value)}
                        onClick={() => {
                            setActive(true);
                        }}
                    >
                        <HiOutlineMenuAlt1 />
                    </div>
            }
        </Fragment>

    )
}

export const SideNavBreak = () => {
    return (
        <Break className={classCombine("side-nav-break")} />
    )
}

interface SideNavGroupProps {
    children?: ReactNode,
}

export const SideNavGroup = ({ children }: SideNavGroupProps) => {

    const checkPerms = () => {
        let check = false;
        Children.forEach(children, (child: ReactElement) => {
            if (child.props.parent) {
                Children.forEach(child.props.children, (child: ReactElement) => {
                    if ((child.props.route as RouteType)?.condition) {
                        check = true;
                    }
                });
            } else if ((child.props.route as RouteType)?.condition) {
                check = true;
            }

        });
        return check;
    }

    return (
        checkPerms() &&
        <Fragment>
            {
                children
            }
            <SideNavBreak />
        </Fragment>
    )
}

const findRoute = (children): RouteType => {
    // const routes = Children.toArray(children);
    let route;

    Children.forEach(children, (child) => {
        if (child.props.route.condition && !route) {
            route = child.props.route
        }
    })

    // return (routes[0] as ReactElement)?.props?.route;
    return route;
}

const SideNavSubGroup = ({ parent, children }) => {
    const [id, setId] = useState(uuid());
    const ref = useRef(null);
    const routeData = getRouteFromUrl()();
    const checkWidth = getWidth()();
    const breakPoint = useBreakpoint();
    const [active, setActive] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(true);
    const themeContext = useContext(ThemeContext);
    const checkPerms = useMemo(() => {
        let check = false;
        let checkVisible = false;
        Children.forEach(children, (child: ReactElement) => {
            if ((child.props.route as RouteType)?.url === routeData.route.url) {
                check = true;
            }
            checkVisible = checkVisible || (child.props.route as RouteType)?.condition;
        });
        if (check && !active && !checkWidth) {
            setActive(true);
        }
        if (!checkVisible) {
            setVisible(false);
        }
        return {
            data: {
                className: check ? "active" : "",
                style: check ? { borderRadius: "4px 0px 0px 4px" } : {}
            },
            check: check
        };
    }, [children, breakPoint])

    useEffect(() => {
        if (document.getElementById(id)) document.getElementById(id).style.top = `calc(50% - ${document.getElementById(id)?.clientHeight / 2}px)`;
    }, [breakPoint])


    return (
        visible && <div
            className={classCombine("side-nav-sub-group-wrapper", themeContext.value)}
        >
            {
                <div
                    className={classCombine("side-nav-sub-group-parent", themeContext.value)}

                    onClick={() => {
                        if (!checkWidth) setActive(!active)
                    }}
                >
                    {checkWidth ? <>
                        {
                            cloneElement(parent, {
                                hasToolTip: true,
                                route: findRoute(children),
                                ...checkPerms.data
                            })
                            // parent
                        }
                        {
                            <div
                                className={classCombine("side-nav-sub-group-arrow", checkPerms.check ? "active" : "", themeContext.value)}
                            >
                                <MdKeyboardArrowRight size={20} />
                            </div>

                        }
                    </>
                        :
                        <>
                            {
                                // cloneElement(parent, {
                                //     // hasToolTip: true,
                                //     route: findRoute(children),
                                //     onClick: () => {
                                //         setActive(!active)
                                //     },
                                //     ...checkPerms.data
                                // })

                                <div
                                    className={classCombine("side-nav-option", "sub-group", themeContext.value)}
                                >
                                    {
                                        cloneElement((parent.props?.icon ?? parent.props.route.icon) as React.ReactElement,)
                                    }
                                    {
                                        parent.props.children ?? parent.props.route.pageName
                                    }
                                </div>
                                // parent
                            }
                            {
                                <div
                                    className={classCombine("side-nav-sub-group-arrow", themeContext.value)}
                                >
                                    {active ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                                </div>

                            }
                        </>
                    }

                </div>

            }

            {
                checkWidth ? <div
                    className={classCombine("side-nav-sub-group", themeContext.value)}
                    id={id}
                    ref={ref}
                // style={{
                //     top: `calc(50% - ${document.getElementById(id)?.clientHeight / 2}px)`
                // }}
                >
                    <div
                        className={classCombine("side-nav-sub-group-arrow", themeContext.value)}
                    >

                    </div>
                    <div
                        className={classCombine("side-nav-sub-group-text", themeContext.value)}
                        id={id}
                    >
                        {/* {text} */}


                        {
                            children
                        }
                    </div>

                </div>

                    :
                    active && <div
                        className={classCombine("side-nav-sub-group-dropdown", active ? "active" : "", themeContext.value)}
                    >
                        {
                            children
                        }

                    </div>
            }
        </div>
    )
}


export const SideNav = () => {
    const routes = useRoutes();
    const themeContext = useContext<ThemeContextType>(ThemeContext);
    const authContext = useContext<AuthContextType>(AuthContext);

    const announcementRoutes = [
        routes.authenticated.announcements,
        routes.authenticated.announcements_create,
    ]

    const businessDiscussionRoutes = [
        routes.authenticated.business_discussions,
        routes.authenticated.create_business_discussion,
        routes.authenticated.business_discussions_categories,
        routes.authenticated.business_discussions_types,
        routes.authenticated.business_discussions_categories_edit,
        routes.authenticated.business_discussions_create_type,
        routes.authenticated.business_discussions_create_category,
        routes.authenticated.business_discussions_types_edit,
    ]

    const roleRoutes = [
        routes.authenticated.roles,
        routes.authenticated.roles_create,
        routes.authenticated.roles_edit
    ]

    const inventoryRoutes = [
        routes.authenticated.inventory,
        routes.authenticated.add_item,
        routes.authenticated.create_inventory_type,
        routes.authenticated.types,
        routes.authenticated.types_edit,
    ]

    const expensesRoutes = [
        routes.authenticated.upload_receipt,
        routes.authenticated.view_expenses,
    ]

    const terminalRoutes = [
        routes.authenticated.terminals,
        routes.authenticated.terminals_edit,
        routes.authenticated.create_terminal,
    ]

    const userRoutes = [
        routes.authenticated.users,
        routes.authenticated.users_create,
        routes.authenticated.users_edit,
        routes.authenticated.pending_users,
        routes.authenticated.pending_users_edit,
    ]

    const reportRoutes = [
        routes.authenticated.report,
    ]

    const customFormRoutes = [
        routes.authenticated.view_custom_forms,
        routes.authenticated.view_custom_form_types,
        routes.authenticated.create_custom_form,
        routes.authenticated.create_custom_form_type
    ]

    const administrationRoutes = [
        routes.authenticated.manage_organization,
        ...announcementRoutes,
        ...businessDiscussionRoutes,
        ...inventoryRoutes,
        ...userRoutes,
        ...terminalRoutes,
        ...expensesRoutes,
        routes.authenticated.weekly_receipt,
        ...roleRoutes,
        ...customFormRoutes
    ];

    const pdTruckRoutes = [

        routes.authenticated.pd_trucks,
        routes.authenticated.pd_trucks_create,
        routes.authenticated.pd_trucks_mmr,
    ]

    const pdAssignmentRoutes = [
        routes.authenticated.assignments,
        routes.authenticated.assignments_edit,
        routes.authenticated.create_assignment,
    ]

    const pdDSWRoutes = [
        routes.authenticated.pd_daily_service_worksheets,
        routes.authenticated.pd_daily_service_worksheets_jobs,
        routes.authenticated.pd_daily_service_worksheets_totals,
    ]

    const pdRunSheetRoutes = [
        routes.authenticated.run_sheets_list,
        routes.authenticated.run_sheets_list_submit,
        routes.authenticated.run_sheets_recap_edit,
        routes.authenticated.run_sheets_recap,
        ...pdAssignmentRoutes,
    ]

    const scheduleRoutes = [
        routes.authenticated.view_schedules,
        routes.authenticated.upload_schedule,
        routes.authenticated.view_schedule_groups,
        routes.authenticated.edit_schedule_group,
        routes.authenticated.create_schedule_group
    ]

    const pdRoutes = [
        ...pdTruckRoutes,
        routes.authenticated.pd_trucks_config_edit,
        ...pdDSWRoutes,
        ...pdRunSheetRoutes,
        ...scheduleRoutes,
    ];

    const workOrderRoutes = [
        routes.authenticated.work_orders,
        routes.authenticated.create_work_order,
        routes.authenticated.work_orders_legacy,
        routes.authenticated.suppliers,
        routes.authenticated.suppliers_create,
    ];

    const linehaulTruckRoutes = [
        routes.authenticated.linehaul_trucks,
        routes.authenticated.linehaul_trucks_create,
        routes.authenticated.linehaul_trucks_mmr,
    ]

    const linehaulRunSheetRoutes = [
        routes.authenticated.linehaul_run_sheets_self,
        routes.authenticated.linehaul_run_sheets_recap,
        routes.authenticated.linehaul_run_sheets_recap_edit,
        routes.authenticated.linehaul_run_sheets_submit,
    ]

    const linehaulAssignmentRoutes = [
        routes.authenticated.linehaul_assignments,
        routes.authenticated.linehaul_create_assignment,
        routes.authenticated.linehaul_assignments_edit,
    ]

    const linehaulLocationRoutes = [
        routes.authenticated.linehaul_locations,
        routes.authenticated.linehaul_locations_create,
        routes.authenticated.linehaul_locations_edit,
    ]

    const linehaulRoutes = [
        ...linehaulTruckRoutes,
        routes.authenticated.linehaul_truck_config_edit,
        ...linehaulRunSheetRoutes,
        ...linehaulAssignmentRoutes,
        ...linehaulLocationRoutes,
    ];

    const miscRoutes = [
        routes.authenticated.settings,
        routes.authenticated.submit_bug_report,
        routes.authenticated.support,
        routes.authenticated.feature_request
    ];

    // useEffect(() => {
    //     document.getElementById("content-nav-container").style.gridTemplateColumns = `${document.getElementById("nav-parent")?.clientWidth ?? 50}px auto`;
    // }, [document.getElementById("nav-parent")?.clientWidth])

    const organizationRoutes = [
        routes.authenticated.view_organizations,
        routes.authenticated.create_organization,
        routes.authenticated.edit_organization,
    ]
    return (
        <div
            className={classCombine("nav-parent", themeContext.value)}

        >

            <nav
                className={classCombine("side-nav", themeContext.value)}
            >
                <NavIcon icon={<AiOutlineDashboard />}
                    text={"Dashboard"}
                    route={routes.authenticated.dashboard}
                />
                {
                    !authContext.organization && <NavIcon icon={<RiOrganizationChart />}
                        text={"Organizations"}
                        route={routes.authenticated.view_organizations}
                    // routes={organizationRoutes}
                    />
                }
                <NavIcon icon={<MdOutlineAdminPanelSettings />}
                    text={"Administration"}
                    routes={[
                        routes.authenticated.manage_organization,
                        routes.authenticated.announcements,
                        routes.authenticated.business_discussions,
                        routes.authenticated.business_discussions_categories,
                        routes.authenticated.business_discussions_types,
                        routes.authenticated.view_expenses,
                        routes.authenticated.weekly_receipt,
                        routes.authenticated.inventory,
                        routes.authenticated.types,
                        routes.authenticated.terminals,
                        routes.authenticated.users,
                        routes.authenticated.pending_users,
                        routes.authenticated.roles,
                        routes.authenticated.view_custom_forms,
                        routes.authenticated.view_custom_form_types
                    ]}
                    hiddenRoutes={administrationRoutes}
                />
                <NavIcon
                    icon={<HiOutlineDocumentReport />}
                    text={"Reports"}
                    routes={[
                        routes.authenticated.report
                    ]}
                    hiddenRoutes={reportRoutes}
                />
                <NavIcon
                    icon={<BsTruck />}
                    text={"P&D"}
                    routes={[
                        routes.authenticated.pd_trucks,
                        routes.authenticated.run_sheets_list,
                        routes.authenticated.run_sheets_recap,
                        routes.authenticated.assignments,
                        routes.authenticated.view_schedules,
                        routes.authenticated.pd_trucks_config_edit,
                        routes.authenticated.pd_daily_service_worksheets,
                        routes.authenticated.pd_daily_service_worksheets_totals,
                        routes.authenticated.pd_daily_service_worksheets_jobs,
                    ]}
                    hiddenRoutes={pdRoutes}
                />
                <NavIcon icon={<FaRoute />}
                    text={"Linehaul"}
                    routes={[
                        routes.authenticated.linehaul_trucks,
                        routes.authenticated.linehaul_run_sheets_self,
                        routes.authenticated.linehaul_run_sheets_recap,
                        routes.authenticated.linehaul_assignments,
                        routes.authenticated.linehaul_locations,
                        routes.authenticated.linehaul_truck_config_edit,
                    ]}
                    hiddenRoutes={linehaulRoutes}
                />
                <NavIcon icon={<HiOutlineWrenchScrewdriver />}
                    text={"Work Orders"}
                    routes={[
                        routes.authenticated.work_orders,
                        routes.authenticated.work_orders_legacy,
                        routes.authenticated.suppliers
                    ]}
                    hiddenRoutes={workOrderRoutes}
                />
                <NavIcon icon={<MdOutlineMiscellaneousServices />}
                    text={"Miscellaneous"}
                    routes={miscRoutes}
                />
            </nav>
            <SideNavExpander
                // route={routes.authenticated.administration}
                icon={<MdOutlineAdminPanelSettings />}
                routes={administrationRoutes}
            >
                <SideNavOption route={routes.authenticated.manage_organization} icon={<RiOrganizationChart />} />

                <SideNavOption route={routes.authenticated.announcements} icon={<AiOutlineNotification />} routes={announcementRoutes} />


                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.business_discussions} icon={<AiOutlineComment />} />}
                >
                    <SideNavOption route={routes.authenticated.business_discussions} icon={<AiOutlineComment />}>Discussions</SideNavOption>
                    <SideNavOption route={routes.authenticated.business_discussions_types} icon={<BsClipboard />} />
                    <SideNavOption route={routes.authenticated.business_discussions_categories} icon={<MdOutlineCategory />} />
                </SideNavSubGroup>


                <SideNavOption route={routes.authenticated.view_expenses} icon={<FaDollarSign />} routes={expensesRoutes} />
                <SideNavOption route={routes.authenticated.weekly_receipt} icon={<BsCalendarWeek />} />


                {/* <SideNavGroup>

                    <SideNavOption route={routes.authenticated.types} icon={<BiCategory/>}/>
                    <SideNavOption route={routes.authenticated.inventory} icon={<MdOutlineInventory2/>}/>
                </SideNavGroup> */}


                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.inventory} icon={<MdOutlineInventory2 />} />}
                >
                    <SideNavOption route={routes.authenticated.inventory} icon={<MdOutlineInventory2 />}>Items</SideNavOption>
                    <SideNavOption route={routes.authenticated.types} icon={<BiCategory />}>Types</SideNavOption>
                </SideNavSubGroup>


                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.view_custom_forms} icon={<HiOutlineDocumentDuplicate />} />}
                >
                    <SideNavOption route={routes.authenticated.view_custom_forms} icon={<HiOutlineDocumentDuplicate />}>Forms</SideNavOption>
                    <SideNavOption route={routes.authenticated.view_custom_form_types} icon={<BiCategory />}>Types</SideNavOption>
                </SideNavSubGroup>

                <SideNavOption route={routes.authenticated.terminals} icon={<MdOutlineLocationOn />} routes={terminalRoutes} />
                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.users} icon={<HiOutlineUserGroup />} />}
                >
                    <SideNavOption route={routes.authenticated.users} icon={<HiOutlineUserGroup />} />
                    <SideNavOption route={routes.authenticated.pending_users} icon={<MdOutlinePending />}>Pending</SideNavOption>
                </SideNavSubGroup>
                <SideNavOption route={routes.authenticated.roles} icon={<TbUserShield />} routes={roleRoutes} />

            </SideNavExpander>


            <SideNavExpander
                // route={routes.authenticated.pd_trucks}
                // icon={<BsTruck/>}
                routes={pdRoutes}
            >
                {/* <SideNavGroup>
                    <SideNavOption route={routes.authenticated.pd_trucks} icon={<BsTruck/>} routes={pdTruckRoutes}>Trucks</SideNavOption>
                </SideNavGroup> */}

                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.pd_trucks} icon={<BsTruck />}>Trucks</SideNavOption>}
                >
                    <SideNavOption route={routes.authenticated.pd_trucks} icon={<BsTable />} routes={pdTruckRoutes}>Trucks</SideNavOption>
                    <SideNavOption route={routes.authenticated.pd_trucks_config_edit} icon={<LuSettings />}>Settings</SideNavOption>
                </SideNavSubGroup>
                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.run_sheets_list} icon={<FaRegEdit />} routes={pdRunSheetRoutes}>Run Sheets</SideNavOption>}
                >
                    <SideNavOption route={routes.authenticated.run_sheets_list} icon={<BsTable />} routes={[routes.authenticated.run_sheets_list_submit]}>My Sheets</SideNavOption>
                    <SideNavOption route={routes.authenticated.run_sheets_recap} icon={<MdOutlineTableView />} routes={[routes.authenticated.run_sheets_recap_edit]}>Recap</SideNavOption>
                    <SideNavOption route={routes.authenticated.assignments} icon={<MdOutlineAssignmentInd />} routes={pdAssignmentRoutes}>Assignments</SideNavOption>
                </SideNavSubGroup>
                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.pd_daily_service_worksheets} icon={<LuFileSpreadsheet />} routes={pdDSWRoutes}>DSW</SideNavOption>}
                >
                    <SideNavOption route={routes.authenticated.pd_daily_service_worksheets} icon={<LuFileSpreadsheet />}>DSW</SideNavOption>
                    <SideNavOption route={routes.authenticated.pd_daily_service_worksheets_totals} icon={<MdOutlineSummarize />}>Daily Totals</SideNavOption>
                    <SideNavOption route={routes.authenticated.pd_daily_service_worksheets_jobs} icon={<IoTerminalOutline />}>Script Log</SideNavOption>
                </SideNavSubGroup>
                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.view_schedules} icon={<GrSchedules />} routes={[routes.authenticated.upload_schedule]}>Schedules</SideNavOption>}
                >
                    <SideNavOption route={routes.authenticated.view_schedules} icon={<GrSchedules />} routes={[routes.authenticated.upload_schedule]}>Schedules</SideNavOption>
                    <SideNavOption route={routes.authenticated.view_schedule_groups} icon={<GrGroup />} routes={[routes.authenticated.view_schedule_groups]}>Groups</SideNavOption>
                </SideNavSubGroup>
            </SideNavExpander>

            <SideNavExpander
                // route={routes.authenticated.work_orders}
                // icon={<HiOutlineWrenchScrewdriver/>}
                routes={linehaulRoutes}
            >
                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.linehaul_trucks} icon={<BsTruck />}>Trucks</SideNavOption>}
                >
                    <SideNavOption route={routes.authenticated.linehaul_trucks} icon={<BsTable />} routes={linehaulTruckRoutes}>Trucks</SideNavOption>
                    <SideNavOption route={routes.authenticated.linehaul_truck_config_edit} icon={<LuSettings />}>Settings</SideNavOption>
                </SideNavSubGroup>


                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.linehaul_run_sheets_self} icon={<FaRegEdit />}>Run Sheets</SideNavOption>}
                >
                    <SideNavOption route={routes.authenticated.linehaul_run_sheets_self} icon={<BsTable />} routes={[routes.authenticated.linehaul_run_sheets_submit]}>My Sheets</SideNavOption>
                    <SideNavOption route={routes.authenticated.linehaul_run_sheets_recap} icon={<MdOutlineTableView />} routes={[routes.authenticated.linehaul_run_sheets_recap_edit]}>Recap</SideNavOption>
                    <SideNavOption route={routes.authenticated.linehaul_assignments} icon={<MdOutlineAssignmentInd />} routes={linehaulAssignmentRoutes}>Assignments</SideNavOption>
                </SideNavSubGroup>
                <SideNavOption route={routes.authenticated.linehaul_locations} icon={<LuClipboardList />} routes={linehaulLocationRoutes} />
            </SideNavExpander>

            <SideNavExpander
                // route={routes.authenticated.work_orders}
                // icon={<HiOutlineWrenchScrewdriver/>}
                routes={workOrderRoutes}
            >
                <SideNavSubGroup
                    parent={<SideNavOption route={routes.authenticated.work_orders} icon={<LuWrench />} />}
                >
                    <SideNavOption route={routes.authenticated.work_orders} icon={<LuWrench />} routes={[routes.authenticated.create_work_order]} />
                    <SideNavOption route={routes.authenticated.work_orders_legacy} icon={<BsTable />}>Legacy</SideNavOption>
                </SideNavSubGroup>
                <SideNavOption route={routes.authenticated.suppliers} icon={<LuClipboardList />} />
            </SideNavExpander>


            <SideNavExpander
                // route={routes.authenticated.work_orders}
                // icon={<HiOutlineWrenchScrewdriver/>}
                routes={miscRoutes}
            >
                <SideNavOption route={routes.authenticated.settings} icon={<LuSettings />}>Settings</SideNavOption>
                <SideNavOption route={routes.authenticated.submit_bug_report} icon={<MdOutlineCreate />}>Submit Bug Report</SideNavOption>
                <SideNavOption route={routes.authenticated.support} icon={<MdOutlineContactSupport />}>Support</SideNavOption>
                <SideNavOption route={routes.authenticated.feature_request} icon={<MdOutlineLibraryAdd />}>Feature Request</SideNavOption>
            </SideNavExpander>

        </div>
    )
}

export default SideNav