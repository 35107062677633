import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/user"


const useUserEndpoints = () => {
    const activity = generateGetEndpoint<Types.User.Tasks>(baseURL + "/activity");
    const check = generatePostEndpoint<{ email: string }, { account: any, accountExists: boolean }>(baseURL + "/check");
    const checkOrg = generatePostEndpoint<{ email: string }, { organizations: Array<any> }>(baseURL + "/check-org");
    const create = generatePostEndpoint<{ user: any, createAccount?: boolean, email?: string }, string>(baseURL + "/create")
    const createMany = generatePostEndpoint<{ users: Array<any> }, string>(baseURL + "/create-many");
    const createPassword = generatePostEndpoint<{ password: string }, string>(baseURL + "/create-password");
    const deleteUser = generatePostEndpoint<{ accountID: string }, string>(baseURL + "/delete");
    const edit = generatePostEndpoint<{ user: any }, any>(baseURL + "/edit")
    const fetch = generatePostEndpoint<{ userId: string }, {
        user: any,
        items: Array<any>,
        typeMap: { [key: string]: string },
        types: Array<any>,
        account: any, records: {
            business_discussions: Array<any>,
            work_orders: Array<any>,
            forms: Array<any>,
        }
    }>(baseURL + "/fetch");
    const fetchActive = generateGetEndpoint<Array<any>>(baseURL + "/fetch-active")
    const fetchAll = generateGetEndpoint<Array<any>>(baseURL + "/fetch-all");
    const fetchAllSensitive = generatePostEndpoint<{ users?: Array<string>, password: string }, Array<any>>(baseURL + "/fetch-all-sensitive")
    const fetchManagers = generateGetEndpoint<Array<any>>(baseURL + "/fetch-managers");
    const fetchPending = generateGetEndpoint<Array<any>>(baseURL + "/fetch-pending");
    const fetchRecords = generatePostEndpoint<{ userId: string, from: number, to: number, recordType?: "business_discussions" | "work_orders" | "forms" | "history" | "all" }, {records: any}>(baseURL + "/fetch-records");
    const fetchSelf = generateGetEndpoint<any>(baseURL + "/fetch-self");
    const forgotPassword = generatePostEndpoint<{ email: string }, string>(baseURL + "/forgot-password");
    const parseSheet = generatePostEndpoint<FormData, Array<any>>(baseURL + "/parse-sheet");
    const resendVerify = generatePostEndpoint<{ userId: string }, string>(baseURL + "/resend-verify");
    const resetPasswordOther = generatePostEndpoint<{ accountId: string, password: string }, string>(baseURL + "/reset-password-other");
    const resetPasswordSelf = generatePostEndpoint<{ password: string }, string>(baseURL + "/reset-password-self");
    const security = generatePostEndpoint<{ userId: string, password: string }, string>(baseURL + "/security");
    const signUp = generatePostEndpoint<{ email: string }, string>(baseURL + "/sign-up");

    return {
        activity,
        check,
        checkOrg,
        create,
        createMany,
        createPassword,
        deleteUser,
        edit,
        fetch,
        fetchActive,
        fetchAll,
        fetchManagers,
        fetchPending,
        fetchRecords,
        fetchSelf,
        fetchAllSensitive,
        forgotPassword,
        parseSheet,
        resendVerify,
        resetPasswordOther,
        resetPasswordSelf,
        security,
        signUp,
    }
}

export {
    baseURL,
    useUserEndpoints
};