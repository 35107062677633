import React, { useContext, useState, createContext } from "react";
import * as DivUtils from "../@utils";
import { ThemeContextType, ThemeContext } from "../theme";
import { FileUploadPropType } from "./types";
import { v4 as uuid } from "uuid";
import { Button } from "../buttons";
import { IoIosAddCircleOutline, IoIosCloseCircleOutline, IoMdAddCircle } from "react-icons/io";
import { Text } from "../typography";
import { useEvent } from "../event";
import { ToolTipLabel } from ".";

export const FileUpload = (props: FileUploadPropType) => {
    const themeContext = useContext<ThemeContextType>(ThemeContext);
    const event = useEvent()
    const [id, setId] = useState(uuid());

    // Delete unrecognized props
    const divProps = Object.assign({}, props as any);
    delete divProps.invertTheme;
    delete divProps.onChange;
    delete divProps.value;
    delete divProps.buttonSize;
    delete divProps.buttonText;
    delete divProps.buttonIcon;
    delete divProps.label;
    delete divProps.onChange;
    delete divProps.simpleFilePreview;
    delete divProps.buttonVariant;
    delete divProps.buttonType;
    delete divProps.hideButtonOnFileInput;
    delete divProps.maxNumberOfFiles;
    delete divProps.hideImagePreview;

    // label?: string;
    // buttonText?: string;
    // buttonSize?: "sm" | "md" | "lg";
    // buttonIcon?: React.ReactElement
    // onChange?: (file: Array<File> | null, event?: React.ChangeEvent<HTMLInputElement>) => void;
    // value?: Array<File> | null,

    const syntheticFormClickHandler = (e) => {
        document.getElementById(props.id || id).click();
    };

    const removeFileHandler = (e, id) => {
        const newArr: Array<File> = Array.from(props.value);

        const indexToRemove = newArr.findIndex(file => file["_uuid"] === id);
        if (indexToRemove > -1) newArr.splice(indexToRemove, 1)

        if (props.onChange) props.onChange(newArr, e);
    };

    const fileUploadHandler = (e) => {
        const fileArray: Array<File> = Array.from(e.target.files)
        if (props.maxNumberOfFiles && fileArray.length > props.maxNumberOfFiles) {
            event(
                {
                    action: "add",
                    options: {
                        expiresIn: 3000,
                        animate: true,
                        children: <span> You can only select up to {props.maxNumberOfFiles} files.</span>,
                        type: "error",
                        eventColor: null
                    }
                }
            );
            return
        };
        if (props.onChange) props.onChange([...fileArray], e);
    }

    return (
        <>
            {props.label ? <label
                className={`input file-upload-label ${themeContext.value}`}
                children={
                    <ToolTipLabel
                        required={props.required}
                        label={props.label}
                        tooltip={""}
                        tooltipType={"default"}
                    />
                }
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            /> : null}

            <div className={"input-container"}>
                {!(props.value && props.hideButtonOnFileInput) && <Button
                    className={"file-upload"}
                    size={props.buttonSize ?? "md"}
                    children={props.buttonText || `Add File${props.multiple ? "s" : ""}`}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => syntheticFormClickHandler(e)}
                    icon={props.buttonIcon ? React.cloneElement(props.buttonIcon, { size: "1em" }) : <IoMdAddCircle size={"1em"} />}
                    padding={props.padding}
                    variant={props.buttonVariant}
                    buttonType={props.buttonType ?? "primary"}
                    disabled={props.disabled}
                />}
                <input
                    {...divProps}
                    id={props.id || id}
                    // set because deleting value doesn't work?
                    // value={""}
                    className={DivUtils.genClassNames(`input`, props, themeContext)}
                    type={"file"}
                    // HARDCODED TO MUTLIPLE = FALSE BECAUSE BACK END FILE HANDLING CAN ONLY HANDLE SINGLE CURRENTLY
                    // multiple={false}
                    onClick={(event) => {
                        event.currentTarget.value = null
                    }}
                    onChange={(e) => { fileUploadHandler(e) }}
                />
                {
                    props.value
                        ?
                        <>
                            {(props.value).map((file, i) => {
                                // console.log( file );
                                return (<div
                                    key={i}
                                    className={`file-upload-metadata-wrapper ${themeContext.value}`}>
                                    <div className={"metadata-text-wrapper"}>
                                        {file["type"].includes("image") && !props.hideImagePreview && <img alt={file["name"]} src={URL.createObjectURL(file as Blob)} />}

                                        {!props.simpleFilePreview && <Text
                                            className={"metadata-text"}
                                        >
                                            {file["name"]}
                                        </Text>}

                                        <IoIosCloseCircleOutline
                                            onClick={(e) => {
                                                removeFileHandler(e, file["_uuid"]);
                                            }}
                                            className={`${themeContext.value}`}
                                            size={20}
                                        />
                                    </div>
                                </div>);
                            })}
                        </>
                        : null
                }
            </div>
        </>
    );
};