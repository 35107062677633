import { key } from "localforage"

export namespace Types {
    export interface APIDataResponse<T = any> {
        payload?: T,
        success: boolean,
        error?: any, 
    }
    
    
    export namespace Terminal {
        export interface FetchAll {
            terminals: Array<any>
            terminalMap: {
                [key: string]: string
            }
            defaultTerminal: any | undefined
        }    
    }

    export namespace BusinessDiscussion {
        export namespace Type {
            export interface FetchAll {
                types: Array<any>,
                typeMap: {
                    [key: string]: any
                }
            }
        }

        export namespace Category {
            export interface FetchAll {
                categories: Array<any>,
                categoryMap: {
                    [key: string]: any
                }
            }
        }
    }

    export namespace Linehaul {
        export namespace Location {
            export interface FetchAll {
                locations: Array<any>
                locationMap: {
                    [key: string]: string
                }
            }   
        } 

        export namespace RunSheet {
            export interface FetchAllLocation {
                locations: Array<any>
                locationMap: {
                    [key: string]: any
                }
            }   
        } 

        export namespace Truck {
            export interface FetchTrucks {
                trucks: Array<any>
                truckMap: {
                    [key: string]: any
                }
            }
        }
    }

    export namespace PD {
        export namespace Assignment {
            export interface FetchAll {
                assignments: Array<any>
                assignmentMap: {
                    [key: string]: any
                }
            }    
        }

        export interface FetchTrucksSimple {
            trucks: Array<any>
            truckMap: {
                [key: string]: any
            }
        }
    }

    export namespace User {
        export interface Tasks {
            // taskData: Array<any>, 
            taskCount: {
                workOrders: number, 
                runSheetsPD: number,
                runSheetsLH: number, 
                locations: number,
                businessDiscussions: number,
                forms: Array<{_id: string, name: string, count: number}>,
                forms_submitted: Array<Form.FormResponse>
            }
        }
    }

    export namespace Report {
        export interface DataTrucks {
            truck: string, status: string, type: string, _id: string
        }
        export interface DataUsers {
            first_name: string, last_name: string, role: {name: string, color: string}, _id: string
        }
        export interface DataAssignments {
            type: string, active: boolean, name: string, _id: string
        }
        export interface Data {
            users: Array<DataUsers>,
            trucks: Array<DataTrucks>
            assignments: Array<DataAssignments>,
        }
    }

    export namespace Form {
        export interface FormManagerNoteType {
            comment: string,
            date: number,
            created_by: string,
            category: string,
            publish: boolean,
        }
        export interface FormRequest {
            category: string,
            fields: {[key: string]: any}
            manager_notes: Array<FormManagerNoteType>
        }
        
        export interface FormResponse extends FormRequest{
            _id: string,
            created: {
                date: number,
                by: string
            }
            approved: {
                status: boolean,
                date: number,
                by: string
            }
            manager_notes: Array<FormManagerNoteType>
            requires_approval: boolean
            attachments: Array<{url: string, name: string}>
        }

        export namespace Template {

            export interface TypeSchemaBase {
                _id?: string,
                key: string,
                column: boolean,
                data_type: "text" | "text box" | "dropdown" | "number" | "date" | "time" | "file" | "date and time",
                required: boolean,
                allowed_values: Array<string>
            }
            export interface TemplateRequest {
                name: string,
                active: boolean,
                type_schema: Array<TypeSchemaBase>,
                requires_approval: boolean,
                dashboard: boolean,
            }

            export interface TemplateTypeSchema extends TypeSchemaBase {
                _id: string
            }

            export interface TemplateResponseBase extends Omit<TemplateRequest, "type_schema"> {
                _id: string
                type_schema: Array<TemplateTypeSchema>
                created: {
                    date: number,
                    by: string
                }
            }
            
            export interface TemplateFetchAll {
                data: Array<TemplateResponseBase>,
                map: {
                    [key: string]: TemplateResponseBase
                }
            }
        }
    }
}