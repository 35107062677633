import { classCombine } from "@fairview-group/utils"
import { CSSProperties, useContext } from "react"
import { BsTrash } from "react-icons/bs"
import { ThemeContext } from "src/@fair"

interface AttachmentProps {
    attachment: { url: string, name: string },
    onDelete?: () => void
    disabled?: boolean
}

export const Attachment = ({ attachment, onDelete, disabled }: AttachmentProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
            }}
        >
            <a
                href={attachment.url}
                target="_blank"
                style={{
                    whiteSpace: "pre-wrap",
                }}
            >
                {
                    attachment.name
                }
            </a>
            {
                onDelete && !disabled && <div
                    onClick={() => {
                        onDelete();
                    }}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    <BsTrash />
                </div>
            }
        </div>
    )
}

export interface AttachmentsProps {
    attachments: Array<{ url: string, name: string }>
    onDelete?: (index: number) => void
    style?: CSSProperties
    disabled?: boolean
}

export const Attachments = ({ attachments, style, onDelete, disabled }: AttachmentsProps) => {
    const themeContext = useContext(ThemeContext);

    return (
        <div
            style={{
                ...(style ?? {}),
                display: "flex",
                flexDirection: "column",
                rowGap: "0.25rem",
                flexWrap: "wrap",
                overflow: "clip"
            }}
        >
            <div
                className={classCombine("announcement-text", themeContext.value)}
            >
                Attachments:
            </div>
            {
                attachments?.length > 0
                    ? <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            rowGap: "0.25rem",
                            flexDirection: "column",
                        }}
                    >
                        {
                            (attachments ?? []).map((attachment, i) => {
                                return (
                                    <Attachment
                                        attachment={attachment}
                                        key={i}
                                        onDelete={() => {
                                            if (onDelete) {
                                                onDelete(i);
                                            }
                                        }}
                                        disabled={disabled || !onDelete}
                                    />
                                )
                            })
                        }
                    </div>
                    : <div>No Attachments</div>
            }
        </div>
    )
}