import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
import { useFormTemplateEndpoints } from "./type";
import { Types } from "src/api/@types";

const baseURL = "/form"

const useFormEndpoints = () => {
    const template = useFormTemplateEndpoints();
    const addNote = generatePostEndpoint<{id: string, note: Partial<any>}, any>(baseURL + "/add-note")
    const approve = generatePostEndpoint<{ids: Array<string>, action: "approve" | "reject"}, any>(baseURL + "/approve")
    const create = generatePostEndpoint<FormData, any>(baseURL + "/create")
    const deleteForm = generatePostEndpoint<{id: string}, any>(baseURL + "/delete")
    const deleteOther = generatePostEndpoint<{ids: Array<string>}, any>(baseURL + "/delete-other")
    const edit = generatePostEndpoint<FormData, any>(baseURL + "/edit")
    const editOther = generatePostEndpoint<FormData, any>(baseURL + "/edit-other")
    const fetch = generatePostEndpoint<{id: string}, any>(baseURL + "/fetch")
    const view = generatePostEndpoint<{to: number, from: number, template?: string}, {data: Array<Types.Form.FormResponse>, template: Types.Form.Template.TemplateResponseBase}>(baseURL + "/view")

    return {
        template,
        addNote,
        approve,
        create,
        deleteForm,
        deleteOther,
        edit,
        editOther,
        fetch,
        view
    }
}

export {
    baseURL,
    useFormEndpoints
};